import { IdsMainButton, IdsIcon } from '@ids/react'
import {
  Header,
  If,
  DsIcon as Icon
} from '@itau-loans-www/shopping/src/components'
import { useMediaQuery } from '@itau-loans-www/shopping/src/hooks'
import { goTo } from 'utils'

import './Error.scss'

const errorsMap = {
  error_attempt_limit: {
    title: 'Você atingiu o limite de tentativas',
    description: 'Volte para a página inicial e inicie uma nova simulação'
  },
  error_something_wrong: {
    title: 'Desculpe, algo deu errado',
    description: 'Não foi possível carregar as informações neste momento'
  }
}

const Error = ({ pageContext }) => {
  const type = pageContext?.type || 'error_something_wrong'

  const isDesktop = useMediaQuery('desktop')
  const isMobile = !isDesktop

  return (
    <>
      <If condition={isDesktop}>
        <Header backRoute="/" />
      </If>
      <section className="error-page container">
        <If condition={isMobile}>
          <button
            className="error-page__close"
            role="Voltar para página inicial"
            onClick={() => goTo('home')}
          >
            <IdsIcon>fechar</IdsIcon>
          </button>
        </If>
        <IdsIcon className="error-page__warning">aviso_outline</IdsIcon>
        <h1 className="error-page__title">{errorsMap[type].title}</h1>
        <p className="error-page__description">{errorsMap[type].description}</p>
        <If condition={isDesktop}>
          <Icon
            className="error-page__icon"
            name="notification"
            type="outlined"
            isLocal={true}
          />
        </If>
        <IdsMainButton
          className="error-page__button"
          onClick={() => goTo('home')}
        >
          Voltar para página inicial
        </IdsMainButton>
      </section>
    </>
  )
}

export default Error
